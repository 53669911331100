import { GameSession } from "./game-session.model";
import { Game } from "./game.model";
import { Player } from "./player.model";
import { Team } from "./team.model";

export class Dashboard {
  numberOfPlayers: number;
  numberOfGames: number;
  numberOfTeams: number;
  numberOfLabled: number;
  lastPlayers: Player[];
  lastGames: Game[];
  lastTeams: Team[];
  upcomingGameSessions: GameSession[];
}
