import { Study } from './study.model';

export class LabellingSpec {

  id?: string;
  name: string;
  description: string;
  study_id: string;
  asset_collection_id: string;
  game_id: string;
  min_times_labelled: number;
  min_times_labelled_equally: number;
  study?: Study;

  constructor(
    question?: Partial<LabellingSpec>
  ) {
    this.id = question?.id;
    this.name = question?.name;
    this.description = question?.description;
    this.study_id = question?.study_id;
    this.asset_collection_id = question?.asset_collection_id;
    this.game_id = question?.game_id;
    this.min_times_labelled = question?.min_times_labelled ?? 5;
    this.min_times_labelled_equally = question?.min_times_labelled_equally ?? 3;
  }

}
