import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BaseService } from '@tcc-mono/shared/data-access';
import { APP_CONFIG, IAppConfig } from '@tcc-mono/shared/app-config';
import { Feature, AnnotationValue } from '@tcc-mono/classifact/shared/data-access/models';

@Injectable({
  providedIn: 'root'
})
export class FeatureService extends BaseService<Feature> {

  constructor(
    _http: HttpClient,
    @Inject(APP_CONFIG) appConfig: IAppConfig
  ) {
    super(_http, appConfig);
    this.path = '/api/features'
  }

  public postAnnotationValue = (featureId: string, annotationValue: AnnotationValue): Observable<unknown> => {
    const usedUrl = this.getUrl(`${this.path}/${featureId}/annotation-values`);
    return this._http.post<any>(usedUrl, annotationValue);
  }

}
