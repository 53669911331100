import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BaseService } from '@tcc-mono/shared/data-access';
import { APP_CONFIG, IAppConfig } from '@tcc-mono/shared/app-config';
import { Study } from '@tcc-mono/classifact/shared/data-access/models';

@Injectable({
  providedIn: 'root'
})
export class StudyService extends BaseService<Study> {
  constructor(_http: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig) {
    super(_http, appConfig);
    this.path = '/api/studies';
  }

  public duplicate = (study: Partial<Study>, newName: string) => {
    return this._http.post(this.getUrl(this.path + '/' + study.id + '/duplicate'), {
      name: newName
    });
  };
}
