import { GameSession } from "./game-session.model";

export class Team {
  id: string;
  name: string;
  activeGameSessionsCount?: number;
  remarks?: string;
  playersCount?: number;
  game_sessions: GameSession[];
}
