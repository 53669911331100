import { LabellingSpecService } from '@tcc-mono/classifact/shared/data-access/classifact-api';
import { LabellingSpec } from '@tcc-mono/classifact/shared/data-access/models';
import { BaseRepository } from '@tcc-mono/shared/data-access';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LabellingSpecRepository extends BaseRepository<LabellingSpec> {

  constructor(
    protected _specService: LabellingSpecService
  ) {
    super(_specService);
  }

}
