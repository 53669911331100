import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GlobalFaStylePipe } from './pipes/global-fa-style.pipe';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StringToObjectPipe } from './pipes/string-to-object.pipe';
import { GetImageFromStatePipe } from './pipes/get-image-from-state.pipe';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule
  ],
  declarations: [
    GlobalFaStylePipe,
    StringToObjectPipe,
    GetImageFromStatePipe,
   ],
  exports: [
    StringToObjectPipe,
    GlobalFaStylePipe,
    GetImageFromStatePipe,
    FontAwesomeModule
  ]
})
export class SharedUiThemingModule {

}
