import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Permission } from '@tcc-mono/classifact/shared/data-access/models';
import { AuthenticationService } from '@tcc-mono/shared/authentication/passport';
import { map, Observable, BehaviorSubject, filter } from 'rxjs';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  private _permissions = new BehaviorSubject<any>(null);
  public permissions$ = this._permissions.asObservable();

  public constructor(
    private readonly _authService: AuthenticationService
  ) {

    this._authService.me$
      .pipe(
        untilDestroyed(this)
      )
      .subscribe((me) => {
        if (me.permissions.length > 0) {
          this._permissions.next(me.permissions);
        }
      })
  }

  public canEdit(page: string): boolean {
    return true;
  }

  public getAll(): Observable<Permission[]> {
    return this._authService.me$
      .pipe(
        map((me) => me.permissions)
      );
  }

  public hasPermission(permission: string): Observable<boolean> {
    return this._permissions
      .pipe(
        filter((permissions) => !!permissions),
        map((permissions) => permissions.find((p: any) => p.name === permission) ? true : false),
      );
  }
}
