export class Study {

  id?: string;
  name: string;
  description: string;
  hypothesis: string;
  research_question: string;

  constructor(study?: Partial<Study>) {
    this.id = study?.id;
    this.name = study?.name;
    this.description = study?.description;
    this.hypothesis = study?.hypothesis;
    this.research_question = study?.research_question;
  }

}
