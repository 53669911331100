import { Injectable } from '@angular/core';
import { AvatarService } from '@tcc-mono/classifact/shared/data-access/classifact-api';
import { Avatar } from '@tcc-mono/classifact/shared/data-access/models';
import { CoreRepository } from '@tcc-mono/shared/data-access';

@Injectable({
  providedIn: 'root'
})
export class AvatarRepository extends CoreRepository<Avatar> {

  public constructor(
    protected _avatarService: AvatarService
  ) {
    super(
      { name: 'avatars' },
      _avatarService
    );
  }
}
