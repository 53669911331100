import { Asset } from './asset.model';
import { Feature } from './feature.model';
import { LabellingSpec } from './labelling-spec.model';

export class GameTask {
  id: string;
  isDemo = false;
  asset: Asset;
  labellingSpec: LabellingSpec;
  features: Feature[]
}
