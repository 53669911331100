import { catchError, filter, switchMap, take } from 'rxjs/operators';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError, finalize, of } from 'rxjs';
import { AuthenticationService } from '../services';
import { ToastService } from '@tcc-mono/shared/ui/services';
import dayjs from 'dayjs';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private isRefeshing = false;

  constructor(
    private _authService: AuthenticationService,
    private _toast: ToastService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        // on first time 401, try to refresh access_token. If that fails, another 401 will be given and a login needs to happen.

        if (
          error.status === 401
          // || error.status === 400
        ) {
          if(req.url.includes('oauth/token')){
            this._authService.login$();
            return EMPTY;
          }
          return this._authService.tokenRefreshing$.pipe(
            filter(refreshing => !refreshing),
            take(1),
            switchMap(() => {
              return this.refreshToken(next, req);
            })
          );
        } else  if (
          error.status === 400
        ) {
          if(req.url.includes('oauth/token')){
            this._authService.login$();
            return EMPTY;
          }
          if(error.error?.data.includes('Tenant could not be identified')){
            this._authService.login$();
            return EMPTY;
          }
          this._toast.show({
            header: error.status.toString(),
            message: error.error?.message || error.message,
            color: 'danger'
          });
          return throwError(
            () => new Error(error.error?.message || error.message)
          );
        }
        else {
          this._toast.show({
            header: error.status.toString(),
            message: error.error?.message || error.message,
            color: 'danger'
          });
          return throwError(
            () => new Error(error.error?.message || error.message)
          );
        }
      })
    );
  }

  private refreshToken(next: HttpHandler, req: HttpRequest<any>) {
    if (this._authService.refreshToken) {
      console.log(this._authService.tokenValidUntil);
      this._authService.setTokenRefreshing(true);
      console.log('start refresh');
      return this._authService.refreshTokens$().pipe(
        take(1),
        switchMap(() => {
          this._authService.setTokenRefreshing(false);
          console.log('end refresh');
          return next.handle(req);
        })
      );
    } else {
      this._authService.setTokenRefreshing(false);
      this._authService.login$();
      return EMPTY;
    }
  }
}
