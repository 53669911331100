import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor, HttpHeaderInterceptor } from './interceptors';
import { Route, RouterModule } from '@angular/router';

const routes: Route[] = [
  {
    path: 'callback',
    loadChildren: () =>
      import('./pages/callback/callback.module').then(m => m.CallbackPageModule)
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)]
})
export class AuthenticationModule {
  public static forRoot(): ModuleWithProviders<AuthenticationModule> {
    return {
      ngModule: AuthenticationModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpErrorInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpHeaderInterceptor,
          multi: true
        }
      ]
    };
  }
}
