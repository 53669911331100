import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BaseService } from '@tcc-mono/shared/data-access';
import { APP_CONFIG, IAppConfig } from '@tcc-mono/shared/app-config';
import { Asset } from '@tcc-mono/classifact/shared/data-access/models';

@Injectable({
  providedIn: 'root'
})
export class AssetService extends BaseService<Asset> {

  constructor(
    _http: HttpClient,
    @Inject(APP_CONFIG) appConfig: IAppConfig
  ) {
    super(_http, appConfig);
    this.path = '/api/assets'
  }

  download = (assetId: string) => {
    return this._http.get<Blob>(`${this.getUrl(this.path)}/${assetId}/download`, {
      responseType: 'blob' as any,
    });
  }

  alert = (assetId: string, gameSessionId: string) => {
    return this._http.post(`${this.getUrl()}/api/game/game-sessions/${gameSessionId}/assets/${assetId}/alert`, {})
  }
}
