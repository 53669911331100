import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { Team, User } from '@tcc-mono/classifact/shared/data-access/models';

import { APP_CONFIG, IAppConfig } from '@tcc-mono/shared/app-config';
import { BaseService, PaginatedResponse, Response } from '@tcc-mono/shared/data-access';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService<User> {

  private _me = new BehaviorSubject<User>(new User());
  public me$ = this._me.asObservable();

  // override path = '/api/user';

  constructor(
    _http: HttpClient,
    @Inject(APP_CONFIG) appConfig: IAppConfig
  ) {
    super(_http, appConfig);
    this.path = '/api/users';
  }

  public initCentralMe$ = () => {
    return this._http.get<Response<User>>(this.getUrl('/api/me'))
      .pipe(
        tap(({ data }) => this._updateMe(data))
      )
  }

  public initMeFromTenant$ = () => {
    return this._http.get<Response<User>>(this.getUrl('/api/tenant/me'))
      .pipe(
        tap(({ data }) => this._updateMe(data)),
        catchError((error: HttpErrorResponse) => {
          return this.initCentralMe$();
        })
      )
  }

  public invite = (user: User) => {
    return this._http.post(this.getUrl(this.path + '/invite'), user);
  }

  public getTeams = (userId: string) => {
    return this.getChild<PaginatedResponse<Team>>(userId, 'teams');
  }

  public setMe(me: User): void{
    this._me.next(me);
  }

  private _updateMe = (me: User) => {
    // let storedValues: any = JSON.parse(localStorage.getItem(`${this.appConfig.appCode}-auth`));
    // storedValues[`${this.appConfig.appCode}-auth`].me.roles = me.roles;
    // localStorage.setItem(`${this.appConfig.appCode}-auth`, JSON.stringify(storedValues));
    // const oldMe = this._me.value;
    // if (oldMe.id === me.id) {
    //   this._me.next({
    //     ...oldMe,
    //     ...me
    //   });
    // } else {
      this._me.next(me);
    //}
  }
}
