import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BaseService, PaginatedResponse, Response } from '@tcc-mono/shared/data-access';
import { APP_CONFIG, IAppConfig } from '@tcc-mono/shared/app-config';
import { Progress } from '@tcc-mono/classifact/shared/data-access/models';

@Injectable({
  providedIn: 'root'
})
export class GameScoreService extends BaseService<Progress> {

  constructor(
    _http: HttpClient,
    @Inject(APP_CONFIG) appConfig: IAppConfig
  ) {
    super(_http, appConfig);
    this.path = '/api/game'
  }

  getScore = (game_session_id: string) => {
    return this.getChild<Response<Progress>>(game_session_id, 'progress')
      .pipe(
        map(({ data }) => data)
      );
  }

  getAwards = (game_session_id: string) => {
    return this.getChild<Response<any>>(game_session_id, 'awards')
      .pipe(
        map(({ data }) => data)
      );
  }


}
