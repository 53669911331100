import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRoute } from '@angular/router';
import { Observable, of, zip } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from '../services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private _authService: AuthenticationService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) { }

  canActivate(): Observable<boolean> {
    // return zip(
    //   this.authService.hasToken$,
    //   this.authService.getMe()
    // )
    //   .pipe(
    //     map(([bool, me]: [boolean, User]) => {
    //       return !!bool && !!me
    //     })
    //   )
    return this._authService.me$
      .pipe(
        map(user => !!user),
        tap(isLoggedIn => {
          if (!isLoggedIn) {
            console.log('AUTH GUARD');
            this._authService.login$();
          }
        })
        // switchMap(() => this._activatedRoute.queryParams
        //   .pipe(
        //     switchMap((params: Params) => {
        //       if (params.code && params.state) {
        //         return this.authService.checkAuth$()
        //       } else {
        //         return this._userService.initMe$()
        //       }
        //     })
        //   ))
      );
  }
}
