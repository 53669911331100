import { Injectable } from '@angular/core';
import { PlayerService } from '@tcc-mono/classifact/shared/data-access/classifact-api';
import { Player } from '@tcc-mono/classifact/shared/data-access/models';
import { CoreRepository } from '@tcc-mono/shared/data-access';

@Injectable({
  providedIn: 'root'
})
export class PlayersRepository extends CoreRepository<Player> {
  public constructor(protected _playerService: PlayerService) {
    super({ name: 'avatars' }, _playerService);
  }
}
