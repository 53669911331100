import { shareReplay } from 'rxjs/operators';
import { BehaviorSubject, debounceTime, distinctUntilChanged, map, Observable, tap, combineLatest, switchMap, of, identity } from 'rxjs';
import { Injectable } from '@angular/core';
import { PaginationData } from '@ngneat/elf-pagination';
import { BaseService } from '../services/base.service';
import { Params } from '@angular/router';
import { ElfUtil } from '@tcc-mono/shared/utils';

@Injectable({
  providedIn: 'root'
})
export class BaseRepository<TEntity extends { id?: string | number }> {

  private readonly _page = new BehaviorSubject<number>(1);
  public readonly page$ = this._page.asObservable();

  private readonly _pagination = new BehaviorSubject<PaginationData>(null);
  public readonly pagination$ = this._pagination.asObservable();

  private readonly _q = new BehaviorSubject<string>('');
  public readonly q$ = this._q.asObservable().pipe(debounceTime(300), distinctUntilChanged());

  private readonly _sort = new BehaviorSubject<string>('');
  public readonly sort$ = this._sort.asObservable().pipe(debounceTime(300), distinctUntilChanged());

  private readonly _activeEntity = new BehaviorSubject<TEntity>(null);
  public readonly activeEntity$ = this._activeEntity.asObservable();

  private readonly _refreshPage = new BehaviorSubject<boolean>(null);
  private readonly _refreshPage$ = this._refreshPage.asObservable();

  private readonly _loading = new BehaviorSubject<boolean>(false);
  public readonly loading$ = this._loading.asObservable();

  private readonly _params = new BehaviorSubject<Params>({});
  public readonly params$ = this._params.asObservable();

  public entities$: Observable<TEntity[]>;

  constructor(
    protected readonly _service: BaseService<TEntity>
  ) {
    this.entities$ = combineLatest([
      this.page$.pipe(distinctUntilChanged()),
      this.q$.pipe(debounceTime(300), distinctUntilChanged()),
      this.sort$.pipe(debounceTime(300), distinctUntilChanged()),
      this._refreshPage$,
      this.params$
    ])
      .pipe(
        map(([page, q, orderby, _, params]: [number, string, string, boolean, Params]) => {
          return {
            ...params,
            page,
            q,
            orderby
          } as Params;
        }),
        tap(() => this._loading.next(true)),
        switchMap((params: Params) => this._service.getAll(params)),
        tap(({ meta }) => {
          const pagData = ElfUtil.convertMetaToElfPagination(meta);
          this._pagination.next(pagData);
          this._loading.next(false);
        }),
        map(({ data }) => data),
        shareReplay()
      );
  }

  public updatePage = (page: number): void => {
    this._page.next(page);
  }

  public setQ = (q: string): void => {
    this._q.next(q);
  }

  public setSort = (field: string, order:string = 'ASC'): void => {
    this._sort.next(`${field} ${order}`);
  }

  public setActiveEntity = (entity: TEntity): void => {
    this._activeEntity.next(entity);
  }

  public setParams = (params: Params): void => {
    this._params.next({
      ...this._params.value,
      ...params
    });
  }

  public triggerRefresh = (): void => {
    this._refreshPage.next(true);
  }

  public hasEntity$ = (id: string): Observable<boolean> => {
    return this.entities$
      .pipe(
        map((entities: TEntity[]) => entities.findIndex((e) => e.id === id) !== -1)
      );
  }

  public selectEntityById = (id: string, setAsActive: boolean = true): Observable<TEntity> => {
    return this.entities$
      .pipe(
        switchMap((entities: TEntity[]) => {
          const entityIndex = entities.findIndex(e => e.id === id);

          if (entityIndex === -1) {
            return this._service.get(id)
              .pipe(
                map(({ data }) => data as TEntity)
              );
          } else {
            return of(entities[entityIndex]);
          }
        }),
        setAsActive ? tap((entity) => this.setActiveEntity(entity)) : identity
      );
  }

  public add = (entity: TEntity): Observable<TEntity> => {
    this._loading.next(true);
    return this._service.post(entity)
      .pipe(
        map(({ data }) => data as TEntity),
        tap((entity: TEntity) => {
          this._loading.next(false);
          this._activeEntity.next(entity);
          this._refreshPage.next(true);
        })
      );
  }

  public update = (entity: TEntity): Observable<TEntity> => {
    this._loading.next(true);
    return this._service.put(entity.id, entity)
      .pipe(
        map(({ data }) => data as TEntity),
        tap((newEntity) => {
          this._loading.next(false);
          this._activeEntity.next(newEntity);
          this._refreshPage.next(true);
        })
      );
  }

  public delete = (entity: TEntity): Observable<unknown> => {
    this._loading.next(true);
    return this._service.delete(entity.id)
      .pipe(
        tap(() => {
          this._loading.next(false);
          this._refreshPage.next(true);
          this._activeEntity.next(null);
        }),
      );
  }

  public setLoading = (loading: boolean) => {
    this._loading.next(loading);
  }
}
