import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BaseService } from '@tcc-mono/shared/data-access';
import { APP_CONFIG, IAppConfig } from '@tcc-mono/shared/app-config';
import { GameSession } from '@tcc-mono/classifact/shared/data-access/models';

@Injectable({
  providedIn: 'root'
})
export class GameSessionService extends BaseService<GameSession> {

  constructor(
    _http: HttpClient,
    @Inject(APP_CONFIG) appConfig: IAppConfig
  ) {
    super(_http, appConfig);
    this.path = '/api/game-sessions'
  }

  public getResultsCsv = (gameSessionId: string) => {
    return this._http.get(`${this.getUrl(`${this.path}/${gameSessionId}/results`)}`, {
      responseType: 'blob'
    });
  }

  public pauseGame = (gameSessionId: string) => {
    return this._http.post(`${this.getUrl(`${this.path}/${gameSessionId}/pause`)}`, {});
  }

  public continueGame = (gameSessionId: string) => {
    return this._http.post(`${this.getUrl(`${this.path}/${gameSessionId}/continue`)}`, {});
  }
}
