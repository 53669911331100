import { IonicRouteStrategy } from '@ionic/angular';
import { LOCALE_ID, NgModule } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { ClassifactWebShellFeatureRoutingModule } from './classifact-web-shell-feature-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { AuthenticationModule } from '@tcc-mono/shared/authentication/passport';
import { HttpClientModule } from '@angular/common/http';
import { FontawesomeImportModule } from '@tcc-mono/classifact/web/shared/fontawesome-import';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  AnimationService,
  FontawesomeTypeService,
  LanguageService
} from '@tcc-mono/shared/ui/theming';
import nl from '@angular/common/locales/nl';
import { registerLocaleData } from '@angular/common';

registerLocaleData(nl);



@NgModule({
  imports: [
    BrowserModule,
    ClassifactWebShellFeatureRoutingModule,
    AuthenticationModule.forRoot(),
    HttpClientModule,
    FontAwesomeModule,
    FontawesomeImportModule
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    { provide: LOCALE_ID, useValue: 'nl-NL' },
  ]
})
export class ClassifactWebShellFeatureModule {
  constructor(
    private _animationService: AnimationService,
    private _fontawesomeService: FontawesomeTypeService,
    private _languageService: LanguageService
  ) {
    this._animationService.initTheme();
    this._fontawesomeService.initTheme();
    this._languageService.initLang();
  }
}
