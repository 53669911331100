import { CollectionService, AssetService } from '@tcc-mono/classifact/shared/data-access/classifact-api';
import { Asset, Collection } from '@tcc-mono/classifact/shared/data-access/models';
import { BaseRepository, PaginatedResponse } from '@tcc-mono/shared/data-access';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CollectionRepository extends BaseRepository<Collection> {

  constructor(
    protected _collectionService: CollectionService,
    private _assetService: AssetService
  ) {
    super(_collectionService);
  }

  public getCollectionAssets = (collectionId: string, params?: Params): Observable<PaginatedResponse<Asset>> => {
    return this._assetService.getAll({ ...params, collection_id: collectionId });
  }

}
