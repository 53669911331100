import { AssetRepository } from '@tcc-mono/classifact/shared/data-access/state';
import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'getStateImg'
})
export class GetImageFromStatePipe implements PipeTransform {

  constructor(
    private _assetRepo: AssetRepository,
    private _domSanitizationService: DomSanitizer
  ) { }

  transform = (imgId: string) => {
    return this._assetRepo.selectAsset(imgId)
      .pipe(
        map(url => url ? this._domSanitizationService.bypassSecurityTrustUrl(url) : '')
      );
  }
}
