import { Team } from './team.model';
import { Game } from './game.model';
import { Progress } from './progress.model';


export class GameSession {
  id: string;
  name?: string;
  notification_email?: string;
  start_date: string;
  end_date: string;
  game?: Game;
  game_id: string;
  team_id: string;
  task_description?: string;
  player_min_times_labelled: number;
  player_max_times_labelled: number;
  status?: string;
  team?: Team;
  score?: Progress;
  finish_text?: string;
  delete_flagged?: boolean;
}
