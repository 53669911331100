import { InAppBrowser, InAppBrowserEvent, InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { IUserTentant } from './../models/authentication.model';
import { APP_CONFIG, IAppConfig } from '@tcc-mono/shared/app-config';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import {
  filter,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import {
  AuthResult,
  IApiResult,
  IAuthResult,
  IUser,
  LoginParams,
  RefreshParams,
  TokenParams,
} from '../models';
import * as CryptoJS from 'crypto-js';
import { AuthStorageService } from './auth-storage.service';
import { Platform } from '@ionic/angular';
import { randomString } from '@tcc-mono/classifact/web/shared/utils';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private _silentRenewTrigger = new BehaviorSubject<boolean>(false);
  private _silentRenewTrigger$ = this._silentRenewTrigger.asObservable();

  private _selectedTenant = new BehaviorSubject<IUserTentant>(null);
  public selectedTenant$ = this._selectedTenant.asObservable();

  private _availableTenants = new BehaviorSubject<IUserTentant[]>([]);
  public availableTenants$ = this._availableTenants.asObservable();

  private _tokenRefreshing = new BehaviorSubject<boolean>(false);
  public tokenRefreshing$ = this._tokenRefreshing.asObservable();

  private _me = new BehaviorSubject<any>(null);
  public me$ = this._me.asObservable();

  private readonly RENEW_TIME_BEFORE_TOKEN_EXPIRES_IN_SECONDS = this._appConfig.authentication.renewTimeBeforeTokenExpiresInSeconds;
  private readonly API_URL = this._appConfig.apiUrl;
  private readonly BASE_URL = 'oauth';
  private readonly LOCAL_STORAGE_KEY = this._storage.LOCAL_STORAGE_KEY;
  private readonly POST_LOGOUT_REDIRECT_URL = this._appConfig.authentication.postLogoutRedirectUrl;
  private authResult: AuthResult;

  private get _loginState() {
    return this._storage.get<any>('login_state');
  }

  public get accessToken() {
    return this.authResult?.access_token;
  }

  public get refreshToken() {
    return this.authResult?.refresh_token;
  }

  public get tokenValidUntil() {
    return this.authResult?.valid_until;
  }

  public get selectedTenant() {
    return this._storage.get<IUserTentant>('selected_tenant');
  }

  public get returnUrl() {
    return this._loginState?.returnUrl;
  }
  public get queryParams() {
    return this._loginState?.queryParams;
  }


  public setTokenRefreshing(isRefreshing: boolean) {
    this._tokenRefreshing.next(isRefreshing);
  }

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _http: HttpClient,
    private _router: Router,
    private _storage: AuthStorageService,
    @Inject(APP_CONFIG) private _appConfig: IAppConfig,
    private iab: InAppBrowser,
    private platform: Platform
  ) {
    if (this.selectedTenant) {
      this._selectedTenant.next(this.selectedTenant);
    }
  }

  public checkAuth$ = () => {
    return this._activatedRoute.queryParams.pipe(
      // filter(({ code, state }) => !!code && !!state),
      switchMap((params: Params) => {
        if (params['code'] && params['state']) {
          return this._getAccessToken$(params);
        } else {
          this.login$;
          return of({});
        }
      }),
      switchMap(() => this.initMe())
    );
  };

  public login$ = () => {

    this.authResult = new AuthResult();
    const state = randomString(40);
    const code_verifier = randomString(128);
    const url = window.location.href.replace(window.location.origin, "");
    const queryParams = JSON.stringify(this._activatedRoute.snapshot.queryParams);

    let reload = false;

    this._storage.set({ key: 'login_state', value: {'state' : state, 'return_url': url, 'query_params' : queryParams, 'code_verifier' : code_verifier} });

    const params: LoginParams = new LoginParams(
      {
        state: state,
        code_challenge: this._createCodeChallenge(code_verifier)
      },
      this._appConfig
    );

    const authUrl = `${this.API_URL}/${this.BASE_URL}/authorize?${new URLSearchParams(params as any).toString()}`;

    if (this.platform.is('capacitor')) {

      let options: InAppBrowserOptions = {};

      if (this.platform.is('ios')) {
        options = {
          zoom: 'no',
          location:'no',
          toolbar: 'no'
        }
      } else {
        options = {
          location: 'no'
        }
      }

      const browser = this.iab.create(authUrl, '_blank', options);

      browser.on('loadstop').pipe(
        filter(event =>
          event.url.includes('/forgot-password')
        )
      ).subscribe((event: InAppBrowserEvent) => {
        browser.executeScript({ code: 'document.getElementsByTagName("h2")[0].innerHTML' }).then((data) => {
          console.log(data[0])
          if(data[0].includes('Check your mailbox')) {
            reload = true;
            browser.close();
          }
        });
      });

      browser
        .on('exit')
        .pipe(take(1))
        .subscribe((event: InAppBrowserEvent) => {
          console.log(reload);

          if(reload) {
            this.login$();
          }
        })

      browser
        .on('loadstart')
        .pipe(
          filter(event =>
            event.url.includes(this._appConfig.authentication?.redirectUrl)
          ),
          take(1),
          tap(event => {
            reload = false;
            browser.close();
            const params = this._parseQueryParams(event.url);

            this._router.navigate(['/auth/callback'], { queryParams: params });
          })
        )
        .subscribe();
    } else {
      window.location.href = `${this.API_URL}/${
        this.BASE_URL
      }/authorize?${new URLSearchParams(params as any).toString()}`;
    }
  };

  public refreshTokens$ = (): Observable<any> => {
    const params = new RefreshParams(
      {
        refresh_token: this.refreshToken
      },
      this._appConfig
    );

    return this._http
      .post<IAuthResult>(`${this.API_URL}/${this.BASE_URL}/token`, params)
      .pipe(
        tap((response: IAuthResult) => this._handleTokenResponse(response))
      );
  };

  public logout = (): void => {
    this.authResult = new AuthResult();
    this._storage.set({ key: 'login_state', value: null });
    this._availableTenants.next([]);
    this._me.next(null);

    const logoutUrl = `${this.API_URL}/logout?redirect_url=${this.POST_LOGOUT_REDIRECT_URL}`;

    if (this.platform.is('capacitor')) {

      let options: InAppBrowserOptions = {};

      if (this.platform.is('ios')) {
        options = {
          zoom: 'no',
          location:'no',
          toolbar: 'no',
          hidden: 'no'
        }
      } else {
        options = {
          location: 'no',
          hidden: 'no'
        }
      }

      setTimeout(() => {
        const logoutBrowser = this.iab.create(
          logoutUrl,
          '_blank',
          options
        );

        logoutBrowser
          .on('loadstart')
          .pipe(
            filter(event => event.url.includes(this.POST_LOGOUT_REDIRECT_URL)),
            take(1),
            tap(event => {
              //logoutBrowser.show();
              logoutBrowser.close();
            })
          )
          .subscribe();

          logoutBrowser
          .on('exit')
          .pipe(take(1))
          .subscribe(() => {
            console.log('exit')
            setTimeout(() => this.login$());
          });

      }, 200);
    } else {
      setTimeout(() => (window.location.href = logoutUrl), 200);
    }
  };

  public routeToStoredRoute = () => {

    let url = this._loginState?.return_url || '/';
    const queryParams = this._loginState.query_params ? JSON.parse(this._loginState.query_params) : null;

    if(this._appConfig.authentication.appCallback)
    {
      url = this._appConfig.authentication.appCallback;
    }
    return this._router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() =>
        this._router.navigate([url === '/auth/callback' ? '/' : url], {
          queryParams: queryParams,
          state: { forceReload: true }
        })
      );
  };

  public setSelectedTenant = (tenant: IUserTentant, reload = false) => {
    this._storage.set({ key: 'selected_tenant', value: tenant });
    this._selectedTenant.next(tenant);

    this._http
      .get<IApiResult<IUserTentant>>(`${this.API_URL}/api/tenant/me`)
      .pipe(tap(({ data }) => this._me.next(data))).subscribe();

    if(reload) {
      this._router.navigate(['/auth/callback'], {
        state: { forceReload: true }
      });
    }
  };

  private _listenToSilentRenewTrigger = () => {
    // const tokenExpireTime: number =
    //   this._authState?.authResult?.expires_in || 300;

    // this._silentRenewTrigger$
    //   .pipe(
    //     filter(bool => !!bool),
    //     switchMap(_ =>
    //       timer(
    //         (tokenExpireTime -
    //           this.RENEW_TIME_BEFORE_TOKEN_EXPIRES_IN_SECONDS) *
    //           1000
    //       ).pipe(switchMap(_ => this.refreshTokens$()))
    //     )
    //   )
    //   .subscribe();
  };

  private _getAccessToken$ = ({ code, state }: Params): Observable<any> => {

    if (state !== this._loginState.state) { alert('invalid state'); return EMPTY; }

    const params = new TokenParams(
      {
        code: code,
        code_verifier: this._loginState.code_verifier
      },
      this._appConfig
    );

    return this._http
      .post<IAuthResult>(`${this.API_URL}/${this.BASE_URL}/token`, params)
      .pipe(
        tap((response: IAuthResult) => this._handleTokenResponse(response))
      );
  };

  public initMe = () => {
    return this._http
      .get<IApiResult<IUser>>(`${this.API_URL}/api/me`)
      .pipe(tap(({ data }) => this._handleMeResponse(data)));
  };

  private _handleTokenResponse = (response: IAuthResult) => {
    this.authResult = new AuthResult(response);
  };

  private _handleMeResponse = (user: IUser) => {
    this._me.next(user);
    if(this._appConfig.authentication?.useTenants) {
      if(this.selectedTenant && user.tenants.find(t => t.id == this.selectedTenant.id)) {
        this.setSelectedTenant(this.selectedTenant);
      } else if (user?.tenants?.length > 0) {
        this.setSelectedTenant(user.tenants[0]);
      } else {
        this.logout();
      }
    }
  };

  private _triggerSilentRenew = () => {
    this._silentRenewTrigger.next(true);
  };

  private _createCodeChallenge(code: string): string {
    const codeVerifierHash = CryptoJS.SHA256(
      code
    ).toString(CryptoJS.enc.Base64);
    const codeChallenge = codeVerifierHash
      .replace(/=/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_');

    return codeChallenge;
  }

  private _getURL(url: string) {
    if (url.indexOf('?') > 0) {
      url = url.substring(0, url.indexOf('?'));
    } else {
      url = url;
    }
    return url;
  }

  private _parseQueryParams(url: string): { [key: string]: string | string[] } {
    const queryString = url.split('?')[1];
    if (!queryString) {
      return {};
    }

    const queryParams: { [key: string]: string | string[] } = {};
    const pairs = queryString.split('&');

    pairs.forEach(pair => {
      const [key, value] = pair.split('=');
      const decodedKey = decodeURIComponent(key);
      const decodedValue = decodeURIComponent(value || '');

      if (queryParams.hasOwnProperty(decodedKey)) {
        if (Array.isArray(queryParams[decodedKey])) {
          (queryParams[decodedKey] as string[]).push(decodedValue);
        } else {
          queryParams[decodedKey] = [
            queryParams[decodedKey] as string,
            decodedValue
          ];
        }
      } else {
        queryParams[decodedKey] = decodedValue;
      }
    });

    return queryParams;
  }
}
