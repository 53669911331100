import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BaseService, PaginatedResponse } from '@tcc-mono/shared/data-access';
import { APP_CONFIG, IAppConfig } from '@tcc-mono/shared/app-config';
import { Observable } from 'rxjs';
import { Dashboard } from '@tcc-mono/classifact/shared/data-access/models';

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends BaseService<Dashboard> {

  constructor(
    _http: HttpClient,
    @Inject(APP_CONFIG) appConfig: IAppConfig
  ) {
    super(_http, appConfig);
    this.path = '/api/dashboard'
  }

  public getData = (): Observable<PaginatedResponse<Dashboard>> => {
    const usedUrl = this.getUrl(`${this.path}`);
    return this._http.get<any>(usedUrl);
  }

}
