import * as dayjs from 'dayjs';
import { randomString } from '@tcc-mono/classifact/web/shared/utils';
import { Inject } from '@angular/core';
import { APP_CONFIG, IAppConfig } from '@tcc-mono/shared/app-config';
import { Permission, Role } from '@tcc-mono/classifact/shared/data-access/models';

export interface IAuthenticationState {
  codeVerifier?: string;
  state?: string;
  authResult?: AuthResult;
}

export class AuthenticationState implements IAuthenticationState {
  //codeVerifier: string;
  //state: string;
  authResult?: AuthResult;
  //returnUrl?: string;
  //queryParams?: string;
  //me?: User;

  constructor(authState?: IAuthenticationState) {
    const { codeVerifier, state, authResult } = authState || {};
    //this.state = state || randomString(40);
    //this.codeVerifier = codeVerifier || randomString(128);
    this.authResult = new AuthResult(authResult);
  }
}

export interface IAuthResult {
  access_token?: string;
  expires_in?: number;
  refresh_token?: string;
  token_type?: string;
}

export class AuthResult implements IAuthResult {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  token_type: string;
  valid_until: string;

  constructor(authResult?: IAuthResult) {
    const { access_token, expires_in, refresh_token, token_type } =
      authResult || {};
    this.access_token = access_token || '';
    this.expires_in = expires_in || 300;
    this.refresh_token = refresh_token || '';
    this.token_type = token_type || 'Bearer';
    this.valid_until = dayjs().add(this.expires_in, 'milliseconds').format();
  }
}

export interface IApiResult<TEntity> {
  data: TEntity;
  meta: any;
}

export interface IUser {
  id: string;
  name: string;
  email: string;
  created_at: string;
  updated_at: string;
  tenants: IUserTentant[];
}

export class User implements IUser {
  id: string;
  name: string;
  email: string;
  created_at: string;
  updated_at: string;
  tenants: IUserTentant[];
  selectedTentant: IUserTentant;
  constructor(user: IUser) {
    const { id, name, email, created_at, updated_at, tenants } = user;
    this.id = id;
    this.name = name;
    this.email = email;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.tenants = tenants;
    this.selectedTentant = tenants?.length === 1 ? tenants[0] : null;
  }
}

export interface IUserTentant {
  id: string;
  name: string;
  email?: string;
  super_admin?: boolean;
  permissions?: Permission[];
  roles?: Role[];
}

export interface ILoginParams {
  state: string;
  code_challenge: string;
}

export class LoginParams implements ILoginParams {
  response_type: string;
  state: string;
  client_id: string;
  scope: string;
  code_challenge: string;
  code_challenge_method: string;
  redirect_uri: string;

  constructor(params: ILoginParams, @Inject(APP_CONFIG) appConfig: IAppConfig) {
    const { state, code_challenge } = params;
    this.state = state;
    this.code_challenge = code_challenge;
    this.code_challenge_method = appConfig.authentication.codeChallengeMethod;
    this.response_type = appConfig.authentication.responseType;
    this.client_id = appConfig.authentication.clientId;
    this.scope = appConfig.authentication.scope;
    this.redirect_uri = appConfig.authentication.redirectUrl;
  }
}

export interface ITokenParams {
  code: string;
  code_verifier: string;
}

export class TokenParams implements ITokenParams {
  grant_type: string;
  code: string;
  code_verifier: string;
  redirect_uri: string;
  client_id: string;

  constructor(
    tokenParams: ITokenParams,
    @Inject(APP_CONFIG) appConfig: IAppConfig
  ) {
    const { code, code_verifier } = tokenParams;
    this.grant_type = 'authorization_code';
    this.code = code;
    this.code_verifier = code_verifier;
    this.redirect_uri = appConfig.authentication.redirectUrl;
    this.client_id = appConfig.authentication.clientId;
  }
}

export interface IRefreshParams {
  refresh_token: string;
}

export class RefreshParams implements IRefreshParams {
  grant_type: string;
  client_id: string;
  refresh_token: string;

  constructor(
    refreshParams: IRefreshParams,
    @Inject(APP_CONFIG) appConfig: IAppConfig
  ) {
    const { refresh_token } = refreshParams;
    this.grant_type = 'refresh_token';
    this.client_id = appConfig.authentication.clientId;
    this.refresh_token = refresh_token;
  }
}
