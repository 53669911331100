import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BaseService, PaginatedResponse, Response } from '@tcc-mono/shared/data-access';
import { APP_CONFIG, IAppConfig } from '@tcc-mono/shared/app-config';
import { PlayerTeam } from '@tcc-mono/classifact/shared/data-access/models';

@Injectable({
  providedIn: 'root'
})
export class PlayerTeamService extends BaseService<PlayerTeam> {

  constructor(
    _http: HttpClient,
    @Inject(APP_CONFIG) appConfig: IAppConfig
  ) {
    super(_http, appConfig);
    this.path = '/api/player-teams'
  }
}
