import { NgModule } from '@angular/core';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import {
  faStar as fadStar,
  faChevronLeft as fadChevronLeft,
  faCommand as fadCommand,
  faHouseWindow as fadHouseWindow,
  faPeopleGroup as fadPeopleGroup,
  faDatabase as fadDatabase,
  faUserPlus as fadUserPlus,
  faXmarkLarge as fadXmarkLarge,
  faAlbumCollectionCirclePlus as fadAlbumCollectionCirclePlus,
  faLayerPlus as fadLayerPlus,
  faLayerGroup as fadLayerGroup,
  faEnvelope as fadEnvelope,
  faChevronRight as fadChevronRight,
  faChevronsLeft as fadChevronsLeft,
  faChevronsRight as fadChevronsRight,
  faGraduationCap as fadGraduationCap,
  faPlusSquare as fadPlusSquare,
  faPlus as fadPlus,
  faSave as fadSave,
  faBook as fadBook,
  faBooks as fadBooks,
  faCommentsQuestion as fadCommentsQuestion,
  faTag as fadTag,
  faUser as fadUser,
  faUsersBetweenLines as fadUsersBetweenLines,
  faTrashCan as fadTrashCan,
  faChess as fadChess,
  faGear as fadGear,
  faChessClock as fadChessClock,
  faChessPawn as fadChessPawn,
  faX as fadX,
  faPeopleLine as fadPeopleLine,
  faRightFromBracket as fadRightFromBracket,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faStar as falStar,
  faChevronLeft as falChevronLeft,
  faCommand as falCommand,
  faHouseWindow as falHouseWindow,
  faPeopleGroup as falPeopleGroup,
  faDatabase as falDatabase,
  faUserPlus as falUserPlus,
  faXmarkLarge as falXmarkLarge,
  faAlbumCollectionCirclePlus as falAlbumCollectionCirclePlus,
  faLayerPlus as falLayerPlus,
  faLayerGroup as falLayerGroup,
  faChevronRight as falChevronRight,
  faChevronsLeft as falChevronsLeft,
  faChevronsRight as falChevronsRight,
  faEnvelope as falEnvelope,
  faGraduationCap as falGraduationCap,
  faPlusSquare as falPlusSquare,
  faPlus as falPlus,
  faSave as falSave,
  faBook as falBook,
  faBooks as falBooks,
  faCommentsQuestion as falCommentsQuestion,
  faTag as falTag,
  faUser as falUser,
  faUsersBetweenLines as falUsersBetweenLines,
  faTrashCan as falTrashCan,
  faChess as falChess,
  faGear as falGear,
  faChessPawn as falChessPawn,
  faChessClock as falChessClock,
  faX as falX,
  faPeopleLine as falPeopleLine,
  faRightFromBracket as falRightFromBracket,
  faShirt
} from '@fortawesome/pro-light-svg-icons';
import {
  faStar as farStar,
  faChevronLeft as farChevronLeft,
  faCommand as farCommand,
  faHouseWindow as farHouseWindow,
  faPeopleGroup as farPeopleGroup,
  faDatabase as farDatabase,
  faUserPlus as farUserPlus,
  faXmarkLarge as farXmarkLarge,
  faAlbumCollectionCirclePlus as farAlbumCollectionCirclePlus,
  faLayerPlus as farLayerPlus,
  faLayerGroup as farLayerGroup,
  faChevronRight as farChevronRight,
  faChevronsLeft as farChevronsLeft,
  faChevronsRight as farChevronsRight,
  faEnvelope as farEnvelope,
  faGraduationCap as farGraduationCap,
  faPlusSquare as farPlusSquare,
  faPlus as farPlus,
  faSave as farSave,
  faBook as farBook,
  faBooks as farBooks,
  faCommentsQuestion as farCommentsQuestion,
  faTag as farTag,
  faUser as farUser,
  faUsersBetweenLines as farUsersBetweenLines,
  faTrashCan as farTrashCan,
  faChess as farChess,
  faGear as farGear,
  faChessClock as farChessClock,
  faChessPawn as farChessPawn,
  faX as farX,
  faPeopleLine as farPeopleLine,
  faRightFromBracket as farRightFromBracket,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faStar as fatStar,
  faChevronLeft as fatChevronLeft,
  faCommand as fatCommand,
  faHouseWindow as fatHouseWindow,
  faPeopleGroup as fatPeopleGroup,
  faDatabase as fatDatabase,
  faUserPlus as fatUserPlus,
  faXmarkLarge as fatXmarkLarge,
  faAlbumCollectionCirclePlus as fatAlbumCollectionCirclePlus,
  faLayerPlus as fatLayerPlus,
  faLayerGroup as fatLayerGroup,
  faChevronRight as fatChevronRight,
  faChevronsLeft as fatChevronsLeft,
  faChevronsRight as fatChevronsRight,
  faEnvelope as fatEnvelope,
  faGraduationCap as fatGraduationCap,
  faPlusSquare as fatPlusSquare,
  faPlus as fatPlus,
  faSave as fatSave,
  faBook as fatBook,
  faBooks as fatBooks,
  faCommentsQuestion as fatCommentsQuestion,
  faTag as fatTag,
  faUser as fatUser,
  faUsersBetweenLines as fatUsersBetweenLines,
  faTrashCan as fatTrashCan,
  faChess as fatChess,
  faGear as fatGear,
  faChessPawn as fatChessPawn,
  faChessClock as fatChessClock,
  faX as fatX,
  faPeopleLine as fatPeopleLine,
  faRightFromBracket as fatRightFromBracket,
} from '@fortawesome/pro-thin-svg-icons';
import {
  faStar as fasStar,
  faChevronLeft as fasChevronLeft,
  faCommand as fasCommand,
  faHouseWindow as fasHouseWindow,
  faPeopleGroup as fasPeopleGroup,
  faDatabase as fasDatabase,
  faUserPlus as fasUserPlus,
  faXmarkLarge as fasXmarkLarge,
  faAlbumCollectionCirclePlus as fasAlbumCollectionCirclePlus,
  faLayerPlus as fasLayerPlus,
  faLayerGroup as fasLayerGroup,
  faChevronRight as fasChevronRight,
  faChevronsLeft as fasChevronsLeft,
  faChevronsRight as fasChevronsRight,
  faEnvelope as fasEnvelope,
  faGraduationCap as fasGraduationCap,
  faPlusSquare as fasPlusSquare,
  faPlus as fasPlus,
  faSave as fasSave,
  faBook as fasBook,
  faBooks as fasBooks,
  faCommentsQuestion as fasCommentsQuestion,
  faTag as fasTag,
  faUser as fasUser,
  faUsersBetweenLines as fasUsersBetweenLines,
  faTrashCan as fasTrashCan,
  faChess as fasChess,
  faGear as fasGear,
  faChessPawn as fasChessPawn,
  faChessClock as fasChessClock,
  faX as fasX,
  faPeopleLine as fasPeopleLine,
  faRightFromBracket as fasRightFromBracket,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faStar as fassStar,
  faChevronLeft as fassChevronLeft,
  faCommand as fassCommand,
  faHouseWindow as fassHouseWindow,
  faPeopleGroup as fassPeopleGroup,
  faDatabase as fassDatabase,
  faUserPlus as fassUserPlus,
  faXmarkLarge as fassXmarkLarge,
  faAlbumCollectionCirclePlus as fassAlbumCollectionCirclePlus,
  faLayerPlus as fassLayerPlus,
  faLayerGroup as fassLayerGroup,
  faChevronRight as fassChevronRight,
  faChevronsLeft as fassChevronsLeft,
  faChevronsRight as fassChevronsRight,
  faEnvelope as fassEnvelope,
  faGraduationCap as fassGraduationCap,
  faPlusSquare as fassPlusSquare,
  faPlus as fassPlus,
  faSave as fassSave,
  faBook as fassBook,
  faBooks as fassBooks,
  faCommentsQuestion as fassCommentsQuestion,
  faTag as fassTag,
  faUser as fassUser,
  faUsersBetweenLines as fassUsersBetweenLines,
  faTrashCan as fassTrashCan,
  faChess as fassChess,
  faGear as fassGear,
  faChessPawn as fassChessPawn,
  faChessClock as fassChessClock,
  faX as fassX,
  faPeopleLine as fassPeopleLine,
  faRightFromBracket as fassRightFromBracket,
} from '@fortawesome/sharp-solid-svg-icons';
import {

} from '@fortawesome/free-brands-svg-icons';

const icons = [
  fadStar,
  falStar,
  farStar,
  fasStar,
  fatStar,
  fadChevronLeft,
  falChevronLeft,
  farChevronLeft,
  fatChevronLeft,
  fasChevronLeft,
  fadCommand,
  falCommand,
  farCommand,
  fatCommand,
  fasCommand,
  fadHouseWindow,
  falHouseWindow,
  farHouseWindow,
  fatHouseWindow,
  fasHouseWindow,
  fadPeopleGroup,
  falPeopleGroup,
  farPeopleGroup,
  fatPeopleGroup,
  fasPeopleGroup,
  fassStar,
  fassChevronLeft,
  fassCommand,
  fassHouseWindow,
  fassPeopleGroup,
  fadDatabase,
  falDatabase,
  farDatabase,
  fatDatabase,
  fasDatabase,
  fassDatabase,
  fadUserPlus,
  falUserPlus,
  farUserPlus,
  fatUserPlus,
  fasUserPlus,
  fassUserPlus,
  fadXmarkLarge,
  falXmarkLarge,
  farXmarkLarge,
  fatXmarkLarge,
  fasXmarkLarge,
  fassXmarkLarge,
  fadAlbumCollectionCirclePlus,
  falAlbumCollectionCirclePlus,
  farAlbumCollectionCirclePlus,
  fatAlbumCollectionCirclePlus,
  fasAlbumCollectionCirclePlus,
  fassAlbumCollectionCirclePlus,
  fadLayerPlus,
  falLayerPlus,
  farLayerPlus,
  fatLayerPlus,
  fasLayerPlus,
  fassLayerPlus,
  fadLayerGroup,
  falLayerGroup,
  farLayerGroup,
  fatLayerGroup,
  fasLayerGroup,
  fassLayerGroup,
  fadEnvelope,
  falEnvelope,
  farEnvelope,
  fatEnvelope,
  fasEnvelope,
  fassEnvelope,
  fadChevronRight,
  fadChevronsLeft,
  fadChevronsRight,
  falChevronRight,
  falChevronsLeft,
  falChevronsRight,
  farChevronRight,
  farChevronsLeft,
  farChevronsRight,
  fatChevronRight,
  fatChevronsLeft,
  fatChevronsRight,
  fasChevronRight,
  fasChevronsLeft,
  fasChevronsRight,
  fassChevronRight,
  fassChevronsLeft,
  fassChevronsRight,
  fadGraduationCap,
  falGraduationCap,
  farGraduationCap,
  fatGraduationCap,
  fasGraduationCap,
  fassGraduationCap,
  fadPlusSquare,
  falPlusSquare,
  farPlusSquare,
  fatPlusSquare,
  fasPlusSquare,
  fassPlusSquare,
  fadSave,
  falSave,
  farSave,
  fatSave,
  fasSave,
  fassSave,
  fadPlus,
  falPlus,
  farPlus,
  fatPlus,
  fasPlus,
  fassPlus,
  fadBook,
  falBook,
  farBook,
  fatBook,
  fasBook,
  fassBook,
  fadBooks,
  falBooks,
  farBooks,
  fatBooks,
  fasBooks,
  fassBooks,
  fadCommentsQuestion,
  falCommentsQuestion,
  farCommentsQuestion,
  fatCommentsQuestion,
  fasCommentsQuestion,
  fassCommentsQuestion,
  fadTag,
  falTag,
  farTag,
  fatTag,
  fasTag,
  fassTag,
  fadUser,
  falUser,
  farUser,
  fatUser,
  fasUser,
  fassUser,
  fadUsersBetweenLines,
  falUsersBetweenLines,
  farUsersBetweenLines,
  fatUsersBetweenLines,
  fasUsersBetweenLines,
  fassUsersBetweenLines,
  fadTrashCan,
  falTrashCan,
  farTrashCan,
  fatTrashCan,
  fasTrashCan,
  fassTrashCan,
  fadChess,
  falChess,
  farChess,
  fatChess,
  fasChess,
  fassChess,
  fadGear,
  falGear,
  farGear,
  fatGear,
  fasGear,
  fassGear,
  fadChessClock,
  falChessClock,
  farChessClock,
  fatChessClock,
  fasChessClock,
  fassChessClock,
  fadX,
  falX,
  farX,
  fatX,
  fasX,
  fassX,
  fadPeopleLine,
  falPeopleLine,
  farPeopleLine,
  fatPeopleLine,
  fasPeopleLine,
  fassPeopleLine,
  fadRightFromBracket,
  falRightFromBracket,
  farRightFromBracket,
  fatRightFromBracket,
  fasRightFromBracket,
  fassRightFromBracket,
  faShirt,
  fadChessPawn,
  falChessPawn,
  farChessPawn,
  fatChessPawn,
  fasChessPawn,
  fassChessPawn,
]

@NgModule({
  imports: [FontAwesomeModule],
})
export class FontawesomeImportModule {
  constructor(
    lib: FaIconLibrary
  ) {
    lib.addIcons(...icons);
  }
}
