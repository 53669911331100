export class Progress {
  progress: number;
  team_progress: number;
  team_score: number;
  score: number;
  team_iaa: number;
  iaa: number;
  label_count: number;
  player_max_times_labelled: number;
  player_min_times_labelled: number;
  task_count: number;
}
