import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services';

@Injectable()
export class HttpHeaderInterceptor implements HttpInterceptor {
  constructor(private _authService: AuthenticationService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this._authService.accessToken;
    const tenant = this._authService.selectedTenant;

    if (token) {
      req = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + token)
      });
    }

    if (tenant) {
      req = req.clone({
        headers: req.headers.set('X-Tenant', tenant.id)
      });
    }


    return next.handle(req);
  }
}
