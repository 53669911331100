import { AnnotationValue } from './annotation-value.model';

export class Feature {
  id?: string;
  name: string;
  description: string;
  annotation_method: string;
  labelling_spec_id: string;
  display_options: string;
  annotation_tooling: string;
  annotation_values?: AnnotationValue[];

  constructor(
    feature?: Partial<Feature>
  ) {
    this.id = feature?.id;
    this.name = feature?.name;
    this.description = feature?.description;
    this.annotation_method = feature?.annotation_method;
    this.labelling_spec_id = feature?.labelling_spec_id;
    this.display_options = feature?.display_options;
    this.annotation_tooling = feature?.annotation_tooling;
  }
}
