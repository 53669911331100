import { Component } from '@angular/core';
import { UserService } from '@tcc-mono/classifact/shared/data-access/classifact-api';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
import utc from 'dayjs/plugin/utc';
import { environment } from '../environments/environment';

@Component({
  selector: 'tcc-mono-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private _userService: UserService) {
    dayjs.locale('nl');
    dayjs.extend(utc);

    this._userService.me$.subscribe(user => {
      if(
        user?.roles?.length === 1
        && !user.super_admin
        && user.roles[0].name == "player"
        )
         {
          window.location.href = environment.appUrl;
      }
    });
  }
}
