import { Params } from '@angular/router';
import {
  Player,
  Team,
  User
} from '@tcc-mono/classifact/shared/data-access/models';
import { Injectable } from '@angular/core';
import {
  TeamService,
  PlayerService
} from '@tcc-mono/classifact/shared/data-access/classifact-api';
import { Observable } from 'rxjs';
import {
  BaseRepository,
  CoreRepository,
  PaginatedResponse
} from '@tcc-mono/shared/data-access';

@Injectable({
  providedIn: 'root'
})
export class TeamsRepository extends CoreRepository<Team> {
  public activeTeam$: Observable<Team> = this.activeEntity$;
  public teams$: Observable<Team[]> = this.entities$;

  public constructor(
    protected _teamService: TeamService,
    private _playerService: PlayerService
  ) {
    super({ name: 'teams' }, _teamService);
  }

  public invitePlayer = (player: Partial<User>): Observable<unknown> => {
    return this._playerService.invite(player);
  };

  public getTeam = (teamId: string): Observable<Team> => {
    return this.selectEntity(teamId);
  }

  public getTeamInvites = (
    teamId: string,
    params?: Params
  ): Observable<any> => {
    return this._teamService.getChild(teamId, 'invitations', params);
  };

  public bulkInvitePlayers(teamId: string, file: FormData): Observable<unknown>{
    return this._teamService.bulkInvite(teamId, file);
  }
}
