/**
 * for online development
 */
export const environment = {
  production: false,
  apiUrl: 'https://classifact-api-develop.tccapps.nl',
  appUrl: 'https://classifact-app-develop.tccapps.nl',
  appCode: 'CFT-WEB',
  authentication: {
    clientId: '3b973782-bfa1-4d07-b264-24bbbf108432',
    scope: '*',
    redirectUrl: window.location.origin + '/auth/callback',
    responseType: 'code',
    codeChallengeMethod: 'S256',
    renewTimeBeforeTokenExpiresInSeconds: 20,
    postLogoutRedirectUrl: window.location.origin,
    useTenants: true
  }
};
