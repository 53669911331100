import { APP_CONFIG, IAppConfig } from '@tcc-mono/shared/app-config';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Invitation } from '@tcc-mono/classifact/shared/data-access/models';
import { BaseService, PaginatedResponse, Response } from '@tcc-mono/shared/data-access';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InvitationService extends BaseService<Invitation> {
  constructor(
    _http: HttpClient,
    @Inject(APP_CONFIG) appConfig: IAppConfig
  ) {
    super(_http, appConfig);
    this.path = '/api/invitations'
  }

  public resend = (invitationId: string) => {
    return this._http.post(`${this.getUrl(`${this.path}/${invitationId}/resend`)}`, {});
  }

}
