import { Injectable } from '@angular/core';
import { CoreRepository } from '@tcc-mono/shared/data-access';
import { GameSessionService } from '@tcc-mono/classifact/shared/data-access/classifact-api';
import { GameSession } from '@tcc-mono/classifact/shared/data-access/models';

@Injectable({
  providedIn: 'root'
})
export class GameSessionRepository extends CoreRepository<GameSession> {

  constructor(
    _gameSessionService: GameSessionService
  ) {
    super(
      { name: 'game-sessions' },
      _gameSessionService
    );
  }
}
