import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BaseService, PaginatedResponse } from '@tcc-mono/shared/data-access';
import { APP_CONFIG, IAppConfig } from '@tcc-mono/shared/app-config';

@Injectable({
  providedIn: 'root'
})
export class EnumService extends BaseService<string> {

  constructor(
    _http: HttpClient,
    @Inject(APP_CONFIG) appConfig: IAppConfig
  ) {
    super(_http, appConfig);
    this.path = '/api/enums'
  }

  getEnums = (enumType: string): Observable<PaginatedResponse<string>> => {
    return this.getAll({}, enumType);
  }

}
