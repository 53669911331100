import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { GameTask, Task } from '@tcc-mono/classifact/shared/data-access/models';
import { APP_CONFIG, IAppConfig } from '@tcc-mono/shared/app-config';
import { BaseService, PaginatedResponse } from '@tcc-mono/shared/data-access';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GameTaskService extends BaseService<GameTask> {

  constructor(
    _http: HttpClient,
    @Inject(APP_CONFIG) appConfig: IAppConfig
  ) {
    super(_http, appConfig);
    this.path = '/api/game/tasks'
  }

  public getDemo = (game_session_id: string): Observable<PaginatedResponse<GameTask>> => {
    return this._http.get<PaginatedResponse<GameTask>>(`${this.getUrl(`/api/game/demo`)}`, { params: Object({game_session_id: game_session_id}) });
  }
}
