import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BaseService } from '@tcc-mono/shared/data-access';
import { APP_CONFIG, IAppConfig } from '@tcc-mono/shared/app-config';
import { Team } from '@tcc-mono/classifact/shared/data-access/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TeamService extends BaseService<Team> {

  constructor(
    _http: HttpClient,
    @Inject(APP_CONFIG) appConfig: IAppConfig
  ) {
    super(_http, appConfig);
    this.path = '/api/teams'
  }

  getInvites = (teamId: string) => {
    return this.getChild(teamId, 'invitations');
  }

  public bulkInvite(teamId: string, file: FormData): Observable<any>{
    return this._http.post(
      `${this.getUrl(
        `${this.path}/${teamId}/import-players`
      )}`,
        file
    );
  }
}
