import { TranslocoService } from '@ngneat/transloco';
import { Injectable } from '@angular/core';
import { AlertController, AlertOptions } from '@ionic/angular';
import { from, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AreYouSureService {

  constructor(
    private _alertCtrl: AlertController,
    private readonly _translate: TranslocoService
  ) { }

  public show = (opts?: Partial<AlertOptions>): Observable<any> => {
    const options: AlertOptions = {
      header: opts?.header || this._translate.translate('areYouSure.header'),
      subHeader: opts?.subHeader,
      message: opts?.message || this._translate.translate('areYouSure.message'),
      cssClass: opts?.cssClass,
      inputs: opts?.inputs,
      buttons: opts?.buttons || [
        {
          text: this._translate.translate('areYouSure.cancel'),
          role: 'cancel'
        },
        {
          text: this._translate.translate('areYouSure.delete'),
          role: 'delete'
        }
      ],
      backdropDismiss: opts?.backdropDismiss || false,
      translucent: opts?.translucent || false,
      animated: opts?.animated === false ? false : true, // defaults to true
      mode: opts?.mode,
      keyboardClose: opts?.keyboardClose,
      id: opts?.id,
      enterAnimation: opts?.enterAnimation,
      leaveAnimation: opts?.leaveAnimation,
    };

    return from(this._alertCtrl.create(options))
      .pipe(
        tap(alert => alert.present()),
        switchMap(alert => alert.onDidDismiss()),
        map(({ role }) => role === 'delete')
      );
  }

}
