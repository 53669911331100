import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BaseService } from '@tcc-mono/shared/data-access';
import { APP_CONFIG, IAppConfig } from '@tcc-mono/shared/app-config';
import { AnnotationValue } from '@tcc-mono/classifact/shared/data-access/models';

@Injectable({
  providedIn: 'root'
})
export class AnnotationValueService extends BaseService<AnnotationValue> {

  constructor(
    _http: HttpClient,
    @Inject(APP_CONFIG) appConfig: IAppConfig
  ) {
    super(_http, appConfig);
    this.path = '/api/annotation-values'
  }

}
