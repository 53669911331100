import { IconName } from '@fortawesome/fontawesome-svg-core';

export class AnnotationValue {
  id?: string;
  label?: string;
  output: string;
  display_options?: AnnotationValueDisplayOption | string;
  sequence?: number;
}

export class AnnotationValueDisplayOption {
  id?: string;
  image_url?: AnnotationValueDisplayOptionValue<string>;
  icon_name?: AnnotationValueDisplayOptionValue<IconName>;
  color?: AnnotationValueDisplayOptionValue<string>;
}

export class AnnotationValueDisplayOptionValue<T> {
  value?: T;
  editable: boolean;
}
