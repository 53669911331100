import { Collection } from './collection.model';
import { User } from './user.model';

export enum ImportTemplate {
  Image = 'image',
  Text = 'text'
}

export enum ImportResource {
  Asset = 'asset'
}

export interface IImport {
  id: string;
  file_path: string;
  collection_name: string;
  extension: string;
  template: ImportTemplate;
  resource: ImportResource;
  created_at: string;
  updated_at: string;
  uploaded_by: Partial<User>;
  collection: Partial<Collection>;
  assets_count?: number;
}

export class Import {
  map(arg0: (r: any) => any) {
    throw new Error('Method not implemented.');
  }
  file: File;
  collection_name: string;
  template: string;
  id: string;
  created_at?: string;
  uploaded_by?: Partial<User>;
  collection?: Partial<Collection>;
  assets_count?: number;
}
