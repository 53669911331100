import { Avatar } from './avatar.model';
import { Player } from './player.model';
import { Team } from './team.model';

export class PlayerTeam {
  id: string;
  player_id: string;
  team_id: string;
  player?: Player;
  team?: Team;
}

