import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { SharedTranslationModule } from '@tcc-mono/shared/translation';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { getAppConfigProvider } from '@tcc-mono/shared/app-config';
import { ClassifactWebShellFeatureModule } from '@tcc-mono/classifact/web/shell/feature';
import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [IonicModule.forRoot(), ClassifactWebShellFeatureModule, HttpClientModule, SharedTranslationModule.forRoot(environment)],
  providers: [getAppConfigProvider(environment), InAppBrowser],
  bootstrap: [AppComponent]
})
export class AppModule {}
