import { Avatar } from './avatar.model';
import { Team } from './team.model';
import { User } from './user.model';

export class Player {
  id: string;
  created_at?: string;
  description?: string;
  nickname?: string;
  updated_at?: string;
  user_id: string;
  avatar?: Avatar;
  avatar_id?: string;
  team?: Team;
  teams? : Team[];
  user?: User;
}
