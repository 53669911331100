export class Asset {
  id: string;
  name: string;
  external_ref: string;
  file_path: string;
  content: string;
  asset_type: string;
  collection_id: string;
  created_at: string;
  updated_at: string;
}
